import ApplicationElement from "./application_element";

export default class OtherOptionElement extends ApplicationElement {
  static tagName = "other-option";
  static refs = ["checkbox", "wrapper", "field"];
  static events = {
    change: { method: "update", ref: "checkbox", immediate: true },
  };

  update() {
    // We hide the wrapper of our input and disable the field, so it doesn't get shown and isn't sent on submission
    this.refs.wrapper.hidden = !this.refs.checkbox.checked;
    this.refs.field.disabled = !this.refs.checkbox.checked;
  }
}
