import ApplicationElement from "./application_element";
import { Turbo } from "@hotwired/turbo-rails";

export default class OpenDialogElement extends ApplicationElement {
  static tagName = "open-dialog";
  static refs = ["dialog", "openButton", "closeButton"];
  static events = {
    click: [
      { method: "closeModal", ref: "closeButton" },
      { method: "openModal", ref: "openButton" },
      { method: "closeModalOnClickOutside", ref: "dialog" },
    ],
    keydown: [{ method: "preventModalCloseOnEscape" }],
    keyup: [{ method: "closeModal" }],
  };

  afterConnected() {
    // If the browser still has a cached version of our modal we cannot call `.showModal` since the modal is already open
    // To ensure a consistent state, we close and then open the element
    if (this.refs.dialog.open) this.refs.dialog.close();
    // If there is no openButton, the dialog should be opened immediately.
    if (!this.refs.openButton) this.refs.dialog.showModal();
  }

  openModal() {
    if (this.refs.openButton) {
      this.refs.openButton.setAttribute("aria-expanded", true);
    }
    this.refs.dialog.showModal();
  }

  closeModal(e) {
    if (e.type === "keyup" && e.key !== "Escape") return;
    if (!this.confirmCloseModal()) return;
    this.redirectOrClose();
  }

  // Since closing a dialog with the ESC key is handled by the browser, we need to prevent this default behavior on keydown.
  preventModalCloseOnEscape(e) {
    if (e.key === "Escape") e.preventDefault();
  }

  closeModalOnClickOutside(e) {
    // We listen for clicks on the dialog element itself. When clicking outside the dialog (on the `::backdrop` pseudo-element), the target will be the dialog.
    // Any click inside the dialog, will have the actual content as its target (and not the dialog)
    if (e.target === this.refs.dialog) {
      this.closeModal(e);
    }
  }

  confirmCloseModal() {
    if (!this.dataset.openDialogConfirmMessage) return true;

    return confirm(this.dataset.openDialogConfirmMessage);
  }

  redirectOrClose() {
    if (this.dataset.openDialogRedirectPath) {
      Turbo.visit(this.dataset.openDialogRedirectPath);
    } else {
      if (this.refs.openButton) {
        this.refs.openButton.setAttribute("aria-expanded", false);
      }
      this.refs.dialog.close();
    }
  }
}
